import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {Avatar, Dropdown, DropdownItem, useAppDirection, useAuth} from '@medad-sep/core';

export function UserInfo(): JSX.Element {
  const {direction} = useAppDirection();
  const {t} = useTranslation();
  const {logout} = useAuth();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  return (
    <Dropdown open={isSelectOpen} onOpenChange={setIsSelectOpen} trigger={<Avatar marginHorizontal={1} size="sm" />}>
      <DropdownItem onPress={logout} icon="LogoutSquare02Icon" direction={direction}>
        {t('logout')}
      </DropdownItem>
    </Dropdown>
  );
}
